import * as React from "react";

const HDDarkIcon = (props) => (
  <svg
    width={92}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.75}
      y={0.75}
      width={90.5}
      height={54.5}
      rx={5.25}
      stroke={props.strokeColor}
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6a2 2 0 0 0-2 2v40a2 2 0 0 0 2 2h76a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H8Zm35.086 22.086h-4.578V33h-2.344V21.625h2.344v4.633h4.578v-4.633h2.336V33h-2.336v-4.914ZM49.805 33h1.148c.771 0 1.474-.128 2.11-.383a4.75 4.75 0 0 0 1.648-1.101c.464-.48.82-1.052 1.07-1.72.255-.666.383-1.403.383-2.21v-.54c0-.812-.128-1.549-.383-2.21a4.925 4.925 0 0 0-1.07-1.719 4.703 4.703 0 0 0-1.648-1.101 5.411 5.411 0 0 0-2.086-.391H47.46V33H49.806Zm0-1.828h1.148c.615 0 1.133-.138 1.555-.414.422-.281.74-.69.953-1.227.219-.536.328-1.185.328-1.945v-.555c0-.583-.062-1.096-.187-1.539a3.06 3.06 0 0 0-.54-1.117 2.256 2.256 0 0 0-.882-.68 2.918 2.918 0 0 0-1.203-.234h-1.172v7.71Z"
      fill={props.fillColor}
    />
  </svg>
);

export default HDDarkIcon;
